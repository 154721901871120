@import '/src/helpers/constants';

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  .container {
    background-color: $background;
    width: calc(100% - 240px);
    height: 100%;
    padding: 36px 36px 20px 36px;
    overflow-x: auto;

    &.small {
      width: calc(100% - 56px);
    }

    &_inner {
      width: 100%;
      min-width: 1082px;
      height: 100%;
    }
  }
}

