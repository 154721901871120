@import '/src/helpers/constants';

.wrapper {
  width: 100%;
  padding-bottom: 10px;
}

.titleRow {
  margin-bottom: 24px;
}

.capacityRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-bottom: 24px;

  & div {
    width: 45%;
  }
}

.buttonsList {
  display: flex;
  justify-content: center;
  row-gap: 24px;
  column-gap: 20px;
  flex-wrap: wrap;
}

.iconButton {
  width: 72px !important;
  height: 72px !important;
  background-color: white !important;

  &:active {
    background-color: $secondaryFill !important;
  }
}
