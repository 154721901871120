@import '/src/helpers/constants';


.wrapper {
  position: relative;
}

.input {
  width: 296px;
  caret-color: transparent;
  cursor: pointer;

  &Wrapper {
    cursor: pointer;
    z-index: 4;

    &:active {
      background-color: $background !important;
    }
  }
}

.icon {
  position: absolute;
  top: 12px;
  right: 12px;
  transform: rotateZ(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  transition: transform .2s;

  &Open {
    transform: rotateZ(0deg);
  }
}

.component {
  width: calc(100% - 2px);
  background-color: #fff;
  position: absolute;
  top: 23px;
  left: 1px;
  z-index: 3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s;

  &Open {
    visibility: visible;
    opacity: 1;
  }
}
