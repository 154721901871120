@import '/src/helpers/constants';


.partners {
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        margin-bottom: 40px;

        &Left {
            width: 456px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {}
            &Bottom {}
        }

        &Right {
            width: 421px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {
                display: flex;
                justify-content: flex-end;
                gap: 40px;
            }
            &Bottom {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 48px;
            }
        }

        .mainButton {
            width: 221px;
        }

        &Count {
            display: flex;
            align-items: center;

            &Light {
                color: $textSecondary;
                margin-right: 4px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }

            &Bold {
                color: $textPrimary;
                margin-right: 16px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }
        }
    }
}

.tableCell {
    .tableText {
        font-size: 16px;
        font-family: Manrope-Regular, sans-serif;
        color: $textPrimary;

        &Gray {
            color: $textSecondary;
        }
    }

    &Address {
        width: 250px;

        & .tableText {
            white-space: normal;
        }
    }

    &CenterVertical {
        display: flex;
        align-items: center;
        height: 50px;
    }

    &AlignLeft {
        display: flex;
        justify-content: center;
    }

    &ForPlan {
        width: 128px;
        display: flex;
        justify-content: center;
    }

    &ForDelete {
        width: 67px;
        display: flex;
        justify-content: center;
    }
}

.singlePartnerInfo {
    display: flex;
    align-items: flex-start;
    gap: 80px;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.inputBlock {
    padding: 20px 20px 32px;
    border-radius: 12px;
    background: white;
    //overflow: hidden;
    flex: 0 0 auto;

    &Title {
        font-family: Manrope-Medium, sans-serif;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 60px;
    }

    .input {
        margin-bottom: 51px;

        &TextArea {
            height: 230px;
        }
    }

    .dateInput {
        width: 200px;

        &Long {
            width: 400px;
        }
    }
}

.addressesSearchInput {
    margin-bottom: 22px;
    width: 456px;
}

.hostessLogin {
    & span {
        font-size: 14px;
        color: $textSecondary;
        font-family: Manrope-Regular, sans-serif;
        margin-left: 3px;
        display: block;
        margin-bottom: 8px;
    }
    & p {
        font-size: 16px;
        color: $textSecondary;
        font-family: Manrope-Medium, sans-serif;
        margin-left: 2px;
    }

    &Row {
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &Button {
        border: 0;
        transform: scale(1.2);
    }
}

.weekDay, .holiday {
    margin-bottom: 20px;

    &Name {
        font-size: 14px;
        color: $textSecondary;
        font-family: Manrope-Regular, sans-serif;
        margin-left: 3px;
        margin-bottom: 8px;
    }

    .inputsRow {
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &Left {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &Input {
        width: 88px;
        overflow: hidden;

        &Disabled {
            background-color: #EAECEF !important;
            color: $textSecondary;
            border-radius: 4px;
        }

        &Wrapper {
            overflow: hidden;
        }
    }

    .inputError {
        color: $red;
        position: absolute;
        bottom: -30px;
        left: 9px;
    }
}

.weekDayError {
    color: $red;
    //margin-top: 20px;
}

.holiday {
    &DateInput {
        width: 200px;
    }

    .inputsRow {
        margin-bottom: 34px;
    }

    .inputsRowLeft {
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .holidayItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 404px;
        margin-bottom: 8px;

        &Date {
            color: $textPrimary;
            font-size: 16px;
            font-family: Manrope-Regular, sans-serif;
            width: 200px;
        }

        &Time {
            color: $primary;
            font-size: 16px;
            font-family: Manrope-Regular, sans-serif;
            width: 124px;
        }

        &Button {
            border: 0 !important;
        }
    }
}
