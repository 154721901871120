@import './src/helpers/constants';

.search_input_wrapper {
  flex: 1;
  display: flex;
  justify-content: space-between;
  height: 48px;
  border: 1px solid $border;
  background-color: white;
  border-radius: 12px;
  cursor: text;
  position: relative;
  transition: border-color .2s, background-color .2s;

  &.error {
    border-color: $red !important;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;

    & .search_input_close {
      cursor: default;
    }
  }

  &:hover:not(.disabled):not(.search_input_wrapper_focused):not(.for_widget) {
    border-color: $secondaryBorder;
    background-color: $secondaryFill;
  }

  &:not(.for_widget).search_input_wrapper_focused {
    border-color: $secondaryBorder;
  }

  .search_input_left {
    flex: 0.9;
    display: flex;
    justify-content: space-between;
  }

  .search_input_search {
    width: 48px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search_input {
    flex: 1;
    background-color: unset;

    &.with_padding {
      padding-left: 12px;
    }

    &.is_textarea {
      padding-top: 10px;
      padding-bottom: 10px;
      //height: 100px;
    }
  }

  .search_input_close {
    width: 32px;
    height: 30px;
    margin: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: text;
    border-radius: 8px;
    transition: background-color 0.2s, opacity .2s;

    &.search_input_close_visible {
      opacity: 1;
      cursor: pointer;

      &:hover {
        background-color: $secondaryFill;

        & img {
          filter: invert(15%) sepia(87%) saturate(4831%) hue-rotate(218deg) brightness(94%) contrast(91%);

        }
      }

      &:active {
        background-color: $primaryBg;
      }
    }
  }

  .error_text {
    color: $red;
    position: absolute;
    left: 0;
    bottom: -21px;
    z-index: 150;
    font-size: 13px;
  }

  .input_small {
    color: $textSecondary;
    position: absolute;
    left: 3px;
    top: -26px;
    font-size: 14px;

    &.bottom {
      top: unset;
      bottom: -20px;
      font-size: 12px;
    }
  }
}
