@import '/src/helpers/constants';

.dot {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: $secondary;
  position: absolute;
  cursor: move;
}
