@import '/src/helpers/constants';

.object {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  cursor: move;
  width: 0;
  height: 0;
  z-index: 100000;

  &Texts {
    position: absolute;

    &Small {
      transform: scale(0.8);
    }
  }

  &Title {
    font-family: Manrope-Medium, sans-serif;
    font-size: 18px;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 7px;
  }

  &Cap {
    font-family: Manrope-Regular, sans-serif;
    font-size: 16px;
    color: $textSecondary;
    margin-top: -3px;
    text-align: center;
    white-space: nowrap;
  }
}
