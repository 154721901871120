@import '/src/helpers/constants';

.navbar {
  width: 240px;
  height: 100%;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  &_expander {
    font-size: 16px;
    cursor: pointer;
    margin-left: 30px;
    margin-top: 30px;
  }

  &.small {
    width: 56px;

    .navbar_logo_image {
      width: 40px;
    }

    .navbar_item {
      padding: 12px 14px 12px 12px;
    }

    .navbar_logout {
      padding: 0 8px 0 8px;
    }
  }

  &_address_select {
    position: absolute;
    top: -9999px;
  }

  &_title {
    color: $primary;
    font-family: Manrope-Regular, sans-serif;
    font-size: 32px;
    text-align: center;
  }
  &_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &_image {
      width: 61.52px;
      margin-bottom: 17.83px;
    }
  }

  &_country {
    height: 32px;
    width: 147px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin: 15px 0 0 64px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    transition: background-color .2s;

    &.single {
      cursor: default;

      & .navbar_country_drop {
        display: none;
      }
    }

    &:hover:not(.single) {
      & .navbar_country_name {
        color: $secondaryBorder;
      }

      & .navbar_country_drop {
        filter: invert(58%) sepia(88%) saturate(336%) hue-rotate(184deg) brightness(101%) contrast(93%);
      }
    }

    &:active:not(.single) {
      background-color: $primaryBg;
    }

    &_flag {
      width: 24px;
      height: 24px;
    }

    &_name {
      color: $textSecondary;
      font-size: 13px;
      margin: 0 8px;
      transition: color .2s;
    }

    &_drop {
    }


    .popup_for_dropdown {
      width: 139px;
      border-radius: 8px;
      right: 4px;
      top: 0;
      z-index: 100;

      & .table_popup_item {
        height: 32px;
        border-radius: 4px;
        padding-left: 8px;

        &:active {
          & img {
            filter: unset;
          }
        }
      }

      & img {
        width: 24px;
        height: 24px;
      }

      & p {
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }


  &_top {
    width: 100%;
    display: flex;
    flex-direction: column;
  }


  &_list {
    margin-top: 50px;
    list-style: none;

    &_item {
      margin-bottom: 16px;
      height: 48px;
      position: relative;
    }
  }

  &_item {
    display: flex;
    text-decoration: none;
    color: $textPrimary;
    border-radius: 16px;
    padding: 12px 20px;
    margin: 0 4px;
    cursor: pointer;
    position: relative;
    transition: background-color .2s;

    &:hover:not(.selected) {
      background-color: $primaryBg;
    }

    &:active:not(.selected) {
      background-color: darken($primaryBg, 5);
    }

    &.selected {
      cursor: default;
      .navbar_item_text {
        color: $primary;
      }

      &::before {
        content: "";
        width: 4px;
        height: 24px;
        background-color: $primary;
        position: absolute;
        top: calc(50% - 12px);
        left: -4px;
        border-radius: 0 8px 8px 0;
        animation-name: sideBarBeforeAppear;
        animation-duration: 0.2s;
      }

      & + .navbar_item_count {
        color: $primary;
      }
    }

    &_icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &_img {


      }
      .selected {
        filter: invert(15%) sepia(87%) saturate(4831%) hue-rotate(218deg) brightness(94%) contrast(91%);
      }
    }

    &_text {
      font-family: Manrope-Regular, sans-serif;
      font-size: 18px;
      color: $textPrimary;
      margin-left: 16px;
    }

    &_count {
      font-family: Manrope-Regular, sans-serif;
      color: $textPrimary;
      position: absolute;
      right: 30px;
      top: 13px;

      font-size: 16px;
      background-color: #EFF0FC;
      padding: 0 4px 0 4px;
      border-radius: 4px;
      cursor: default;
    }
  }

  &_logout {
    display: flex;
    //align-items: center;
    flex-direction: column;
    padding: 0 8px 0 24px;
    width: 100%;
    position: relative;

   &_user {
     display: flex;
     flex-direction: column;
     max-width: 80%;
   }

    &_info {
      display: flex;
      //align-items: center;
      //flex-direction: column;
      margin-bottom: 24px;
      width: 100%;
      cursor: pointer;
    }

    &_bold {
      font-size: 16px;
      font-weight: bold;
      color: $textPrimary;
      font-family: Manrope-Regular, sans-serif;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_login {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 76%;
    }

    &_name {
      font-size: 13px;
      color: $textSecondary;
      font-family: Manrope-Regular, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &_expand {
      //width: 24px;
      //height: 24px;
      margin-left: 8px;
      cursor: pointer;
    }

    &_out {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .navbar_logout_bold {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        display: block;
        color: $textPrimary;
        font-family: Manrope-Regular, sans-serif;
        //margin-bottom: 8px;
      }

      &_button {
        background-color: white;
        border-color: $border;
      }
    }
  }
}

.floating_navbar_bg {
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  animation-name: navbarBgAppear;
  animation-duration: 0.3s;

  .floating_navbar_part {
    height: 100%;
    width: fit-content;
  }

  .floating_navbar {
    height: 100%;
    width: fit-content;
    transform: translateX(0);
    animation-name: navbarAppear;
    animation-duration: 0.3s;
  }

  &_closing {
    animation-name: navbarBgHide;
    animation-duration: 0.3s;

    & .floating_navbar {
      animation-name: navbarHide;
      animation-duration: 0.3s;
    }
  }
}

.sidebar_addresses_popup {
  width: fit-content;
  background-color: white;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid $border;
  box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
  display: none;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  bottom: 110px;
  left: 10px;

  &_visible {
    display: block;
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 8px;
    border-radius: 8px;
    color: $textPrimary;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: border-color .2s, background-color .2s;

    &_selected {
      background-color: $primaryBg;
    }

    &:hover:not(.sidebar_addresses_popup_item_selected) {
      border-color: $secondaryBorder;
      background-color: $secondaryFill;
    }

    &:active {
      background-color: $primaryBg;
    }
  }
}

@keyframes sideBarBeforeAppear {
  from {
    left: -8px
  }
  to {
    left: -4px;
  }
}

@keyframes navbarBgAppear {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

@keyframes navbarAppear {
  from {
    transform: translateX(-240px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes navbarBgHide {
  from {
    background-color: rgba(0, 0, 0, 0.6);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes navbarHide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-240px);
  }
}

