@import '/src/helpers/constants';

.horizontal_list {
  display: flex;
  align-items: center;

  &_item {
    height: 44px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 6px;
    position: relative;
    transition: color 0.2s;

    &_pressable_part {
      padding: 8px;
    }

    &.has_popup div{
      //color: #101EA4;
    }

    &:hover:not(.selected):not(.has_popup) div {
      color: $primary;
    }

    &:active:not(.selected):not(.has_popup) div {
      color: lighten($primary, 20%);
    }

    &.selected {
      &::before {
        content: "";
        width: calc(100% - 16px);
        height: 2px;
        background-color: $blueForm;
        position: absolute;
        bottom: 5px;
        left: 8px;
        border-radius: 4px 4px 0 0;
        animation: tableFilterBeforeAppear 0.2s;
      }
    }

    &_popup {
      top: 46px;
      left: 0;
      z-index: 2;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: opacity .2s;

      &_visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@keyframes tableFilterBeforeAppear {
  from {
    bottom: 3px
  }
  to {
    bottom: 5px;
  }
}
