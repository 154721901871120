@import '/src/helpers/constants';

.input_div {
  width: 100%;
  margin-bottom: 20px;

  .row {
    align-items: center;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
  }

  .input {
    flex: 1;
    outline: none;
    border: none;
    padding: 14px 0;
    border-bottom: 1px solid $border;
    color: $darkBlue;
    margin-right: 16px;
    transition: border-color .2s;

    &:focus {
      border-color: $primary;
    }

    &.error_input {
      border-color: $red;
    }
  }

  .error {
    color: $red;
    font-size: 13px;
    margin-left: 56px;
    margin-top: 8px;
    height: 10px;
  }
}
