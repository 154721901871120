@import '/src/helpers/constants';

.plan {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &_btn {
    background-color: white;
  }

  &_left {
    width: calc(100% - 820px);
    position: relative;
    display: flex;
    align-items: center;

    &_scroll {
      overflow-x: auto;
      margin-bottom: -300px;
      padding-left: 20px;
      padding-bottom: 300px;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      &_inner {
        margin-right: 80px;
        width: fit-content;
      }
    }

    &_blur {
      position: absolute;
      right: -1px;
      top: 0;
      height: 100%;
      width: 60px;
      background: linear-gradient(to left, $background, rgba(255, 255, 255, 0));
      pointer-events: none;
    }

    &_list_popup {
      width: 180px;
      height: 100px;
      background-color: white;
      padding: 8px;
      border-radius: 12px;
      border: 1px solid $border;
      box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: -1px 17px 21px 3px rgba(0, 0, 0, 0.05);

      &_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px;
        border-radius: 8px;
        height: 40px;
        color: $textPrimary;
        font-size: 16px;
        cursor: pointer;
        transition: border-color .2s, background-color .2s;

        &:hover {
          border-color: $secondaryBorder;
          background-color: $secondaryFill;
        }

        &:active {
          background-color: $primaryBg;
        }
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    width: 700px;
    justify-content: space-between;
  }
}

.main {
  display: flex;
  margin-top: 60px;
  overflow-y: auto;
  flex-grow: 1;
}

.tools {
  width: 368px;
  flex: 0 0 auto;
  margin-right: 40px;
  max-height: 100%;
  overflow-x: visible;

  &_block {
    margin-bottom: 40px;
    width: 100%;
    padding: 16px 10px 0px 10px;
    background-color: white;
    border-radius: 12px;

    &_title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 26px;
    }
  }
}
