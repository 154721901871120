@import '/src/helpers/constants';

.text {
  padding: 4px 11px;
  border-radius: 20px;
  font-size: 16px;
  font-family: Manrope-Regular, sans-serif;
  font-weight: 400;
  display: inline-block;
  white-space: normal;
}
