@font-face {
  font-family: "Manrope-Bold";
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-SemiBold";
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Medium";
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Regular";
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-Light.ttf") format('truetype');
}

@font-face {
  font-family: "Manrope-Light";
  src: local('Manrope'), url("../public/fonts/Manrope/Manrope-ExtraLight.ttf") format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Manrope-Regular, sans-serif;
  font-weight: 400;
  color: #061F40;
  /*-ms-overflow-style: none;  !* IE and Edge *!*/
  /*scrollbar-width: none;  !* Firefox *!*/
}

/**::-webkit-scrollbar {
  display: none;
}*/

body {
  overflow-y: hidden;
  overflow-x: auto;
}

input, textarea {
  font-size: 16px;
  color: #061F40;
  border: none;
  outline: none;
  resize: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  outline: none;
  border: 0;
  background-color: unset;
}

::placeholder {
  color: #7F8A99;
}

.row {
  display: flex;
}

.modal_wrapper {
  padding: 24px 20px 20px;
  min-height: 284px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal_title {
  font-family: Manrope-Medium, sans-serif;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  height: 40px;
  font-size: 24px;
  text-align: center;
}

.modal_title_no_margin {
  font-family: Manrope-Medium, sans-serif;
  font-weight: 500;
  line-height: 26px;
  font-size: 24px;
  text-align: center;
}

.modal_descr {
  font-family: Manrope-Regular, sans-serif;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 20px;
  font-size: 16px;
  width: 290px;
  color: #7F8A99;
  text-align: center;
}

.modal_buttons_row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#react-charts-portal {
  * {
    font-size: 14px !important;
    font-family: Manrope-Medium, sans-serif !important;
    font-weight: 500 !important;
    color: white !important;
  }
}

#zoom-container {
  height: 100vh;
}

#zoom-content {
  min-width: 1800px;
  transform-origin: top left;
}

/* Disable blue overlay */
* {
  -webkit-tap-highlight-color: transparent;
}
