@import '/src/helpers/constants';

.wrapper {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border: 1px solid $secondary;
  background-color: #E8F0FB;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color .2s, background-color .2s;

  &:hover:not(.disabled) {
    border-color: $primary;
  }
  &:active:not(.disabled) {
    background-color: #CCE0F9;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}
