@import '/src/helpers/constants';


.contacts {
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        margin-bottom: 40px;

        &Left {
            width: 456px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {}
            &Bottom {}
        }

        &Right {
            width: 224px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {
                display: flex;
                justify-content: flex-end;
            }
            &Bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
            }
        }

        &Count {
            display: flex;
            align-items: center;

            &Light {
                color: $textSecondary;
                margin-right: 4px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }

            &Bold {
                color: $textPrimary;
                margin-right: 16px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }
        }
    }
}

.iconButton {
    background-color: #fff;
}


.tableCell {
    .tableText {
        font-size: 16px;
        font-family: Manrope-Regular, sans-serif;
        color: $textPrimary;

        &Gray {
            color: $textSecondary;
        }
    }
}

.tableLabels {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    flex-wrap: wrap;
    gap: 15px;
}

.hiddenInput {
    display: none;
}

.infoModal {
    max-height: 1161px;
    height: 80vh;
    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &Container {
        height: calc(100% - 60px);
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &Text {
        font-size: 30px;
        font-family: Manrope-Medium, sans-serif;
        color: $textPrimary;
        margin-left: 34px;
        margin-bottom: 8px;
        margin-top: 20px;

        &Second {
            margin-left: 27px;
        }
    }
    &TextSmall {
        font-size: 17px;
        font-family: Manrope-Regular, sans-serif;
        color: $textPrimary;
        margin-left: 60px;
    }

    & img {
        width: 744px;
        margin: 30px 60px;
    }
}

.noContactsText {
    font-size: 20px;
    text-align: center;
    margin-top: 48vh;
}
