@import '/src/helpers/constants';

.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.button {
  position: relative;
  top: -0.5px;
  cursor: move;
  height: 100px;
  width: 100px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border;
  background-color: $white;
  border-radius: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &Draggable {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_text {
      position: absolute;
      text-align: center;
      bottom: -30px;
      white-space: nowrap;
    }

    &Dragging {
      position: absolute;
      z-index: 999999;
    }
  }

  &Text {
    position: absolute;
    text-align: center;
    bottom: -30px;
    white-space: nowrap;
  }
}
