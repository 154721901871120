@import '/src/helpers/constants';


.page_title {

  &_text {
    font-size: 24px;
    font-family: Manrope-Medium, sans-serif;
    color: $textPrimary;
  }
}
