@import '/src/helpers/constants';

.wrapper {
  flex-grow: 1;
  height: 100%;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.grid {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 1px, transparent 1px),
  linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 30px 30px; /* Size of the grid squares */
  border: 1px solid #ccc;
  position: relative;
}
