@import './src/helpers/constants';


.login_wrapper {
  background-color: $background;
  height: 100vh;

  &_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 56px;


    &_image {
      width: 61.52px;
      height: 63.04px;
      margin-bottom: 17.83px;
    }

  }
  .registration_form_div {
    border: 1px solid $border;
    width: 400px;
    height: 530px;
    position: absolute;
    padding: 24px 20px;
    background-color: #fff;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .hidden_input {
      display: none;
    }
  }


  .login_button {
    width: 360px;
    margin-top: 58px;
  }
}

.forgotPassword {
  text-align: center;
  color: $textSecondary;
  font-size: 16px;
  margin-top: 16px;
  padding: 8px 0;
  cursor: pointer;
}
