@import '/src/helpers/constants';

.wrapper {
  width: 100%;
}

.tableContainer {
  overflow: auto;
  width: 100%;
  border-radius: 12px;
  background-color: white;
  padding: 0 10px;
}

.table {
  min-width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.thead {
  padding: 8px;
}

.headerRow {
  border-bottom: 2px solid #E6E8EB;
  background-color: white;
}

.itemsRow {
  border-bottom: 1px solid #E6E8EB;

  &Clickable {
    cursor: pointer;
    transition: background-color .2s;

    &:hover {
      background-color: $background;
    }
  }
}

td {
  white-space: nowrap;
  padding: 12px 8px;
  vertical-align: top;
}

th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}

.itemCell {}

.headerCell {
  padding: 8px 16px 8px 8px;
  box-sizing: content-box;

  &Inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &::selection {
      background-color: transparent;
    }
  }

  &Sortable {
    cursor: pointer;
  }

  &Left {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-family: Manrope-Regular, sans-serif;
    font-weight: 500;
    color: $textSecondary;
    text-align: start;
    cursor: default;
  }
}

.sortArrows {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}

.arrow {
  width: 7px;
  height: 7px;
  &Down {
    transform: rotateZ(180deg);
  }

  &Selected {
    filter: invert(58%) sepia(88%) saturate(336%) hue-rotate(184deg) brightness(101%) contrast(93%);
  }
}

.infoComponent {
  position: absolute;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  background-color: white;
  border: 1px solid $border;
  padding: 8px;
  transition: opacity .2s;

  &Visible {
    visibility: visible;
    opacity: 1;
  }

  &Button {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity .2s;

    &:hover {
      opacity: .6;
    }
    &:active {
      opacity: .3;
    }
  }
}
