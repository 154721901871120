@import '/src/helpers/constants';

.row {
  display: flex;
  align-items: center;
}

.input {
  width: 120px;
  caret-color: transparent;
  cursor: pointer;

  &Wrapper {
    cursor: pointer;

    &:active {
      background-color: $background !important;
    }
  }
}

.customHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 40px;
  margin-bottom: 36px;

  .headerText {
    font-size: 22px;
    font-family: Manrope-Medium, sans-serif;
    color: $textPrimary;
  }

  .headerArrows {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .headerArrow {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color .2s;

    &:hover {
      background-color: #F2EAFB;
    }

    &:active {
      background-color: $primaryBg;
    }

    &Left {
      transform: rotate(180deg);
    }
  }
}
