@import '/src/helpers/constants';

.wrapper {}

.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 17px;

  &Text {
    font-family: Manrope-Regular, sans-serif;
    font-size: 18px;
    color: $textPrimary;
  }

  .icon {
    transform: rotateZ(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    transition: transform .2s;

    &Open {
      transform: rotateZ(0deg);
    }
  }
}

.accordion {
  overflow: hidden;
  transition: max-height .2s ease-in;
}

