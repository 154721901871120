@import '/src/helpers/constants';


.react-datepicker {
  background-color: white;
  border: 0 !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  padding: 24px 30px;
  border-radius: 16px !important;

  &-wrapper {
    width: 100%;
  }

  &__month-container {
    width: 100%;
  }

  &__header {
    background-color: white !important;
    border-bottom: 0 !important;
    padding: 0 !important;
    margin-bottom: 24px;
  }

  &__day-names {
    justify-content: space-between;
    display: flex;
  }

  &__day-name {
    font-family: Manrope-Regular, sans-serif;
    color: #718394 !important;
    font-size: 14px !important;
    width: 40px !important;
    text-align: center;
    margin: 0 !important;
  }

  &__triangle {
    display: none;
  }

  &__month {
    margin: 0 !important;
  }

  &__week {
    justify-content: space-between;
    display: flex;
  }

  &__day {
    font-family: Manrope-Medium, sans-serif;
    color: $textPrimary !important;
    font-size: 16px !important;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center;
    border-radius: 6px !important;
    align-items: center;
    margin: 0 0 10px 0 !important;
    transition: background-color .2s, color .2s;

    &:hover {
      background-color: $primaryBg !important;
    }

    &:active {
      color: white !important;
      //background-color: #9B51E0 !important;
      background-color: var(--widget-main-color) !important;
    }
  }

  &__day--today {
    //border: 1px solid #9B51E0;
    border: 1px solid var(--widget-main-color);
    font-weight: bold;
  }

  &__day--selected {
    //background-color: #9B51E0 !important;
    background-color: var(--widget-main-color) !important;
    font-family: Manrope-Bold, sans-serif;
    color: white !important;
  }

  &__day--keyboard-selected {
    background-color: unset !important;
  }

  &__day--outside-month {
    opacity: .3 !important;
  }

  &__day--weekend {}

  &-popper {
    z-index: 4 !important;
  }
}
