@import '/src/helpers/constants';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  padding: 8px;
}

.loader {
  border: 4px solid rgba(0, 0, 255, 0.1);
  border-top: 4px solid blue;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}
