@import '/src/helpers/constants';

.lineDot {
  position: absolute;
  cursor: move;
  width: 0;
  height: 0;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;

  &Inner {
    background-color: $primary;
    flex: 0 0 auto;
  }
}
