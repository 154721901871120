@import '/src/helpers/constants';


.component {
  padding: 27px 4px 1px;

  .option {
    height: 48px;
    padding: 13px 16px;
    color: $textPrimary;
    font-size: 16px;
    font-family: Manrope-Regular, sans-serif;
    cursor: pointer;
    border: 1px solid white;
    margin: 3px 0;
    border-radius: 12px;
    transition: background-color .2s, border .2s;

    &:hover {
      background-color: $secondaryFill;
      border-color: $secondaryBorder;
    }

    &:active {
      background-color: $primaryBg;
    }
  }
}
