@import '/src/helpers/constants';


.reserves {
    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        margin-bottom: 40px;

        &Left {
            width: 592px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {
                display: flex;
                align-items: center;
                gap: 40px;
            }
            &Bottom {
                display: flex;
                align-items: center;
                gap: 40px;
                position: relative;
            }
        }

        &Right {
            width: 500px;
            height: 126px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &Top {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            &Bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
                gap: 40px;
            }
        }

        &Count {
            display: flex;
            align-items: center;

            &Light {
                color: $textSecondary;
                margin-right: 4px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }

            &Bold {
                color: $textPrimary;
                margin-right: 16px;
                font-size: 16px;
                font-family: Manrope-Regular, sans-serif;
            }
        }
    }

    .switchModeButton {
        background-color: white;
    }
}

.dropdown {
    height: 100%;

    .scrollable {
        height: calc(100% - 80px);
        overflow-y: auto;
        padding: 40px 16px 0;
    }

    .bottom {
        width: 100%;
        height: 80px;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
            width: 100%;
        }
    }
}

.filtersCheckBox {
    margin-bottom: 12px;
}

.filtersAccordion {
    margin-bottom: 20px;

    &Inner {
        max-height: 300px;
        overflow-y: auto;
        -ms-overflow-style: unset;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
            display: block;
        }
    }
}

.filtersInput {
    height: 40px !important;
    margin-bottom: 12px;
}

.filtersMargin {
    margin-bottom: 12px;
}

.tableText {
    font-size: 16px;
    font-family: Manrope-Regular, sans-serif;
    color: $textPrimary;

    &Gray {
        color: $textSecondary;
    }
}

.iconButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 4px;
    transition: opacity .2s;

    &:hover {
        opacity: 0.6;
    }

    &:active {
        opacity: 0.3;
    }
}

.tableObjects {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    &Texts {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 4px
    }
}

.tableLabels {
    display: flex;
    align-items: center;
    padding: 16px 8px;
    flex-wrap: wrap;
    gap: 15px;
}

.tagsInfoComponent {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.deleteFilters {
    position: absolute;
    right: 197px;
    bottom: -30px;
}

.tableCell {
    &Address {
        width: 150px;

        & .tableText {
            white-space: normal;
        }
    }
}

.charts {
    width: 100%;
    //overflow-x: auto;
    color: white;

    &Row {
        display: flex;
        align-items: flex-start;
        gap: 44px;
    }

    &Item {
        background-color: white;
        border-radius: 12px;
        padding: 20px;
        flex: 0 0 auto;

        &Header {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &Title {
            font-family: Manrope-Medium, sans-serif;
            color: $textPrimary;
            font-size: 18px;
        }

        &Points {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        &Point {
            display: flex;
            align-items: center;
            gap: 8px;

            &Color {
                width: 8px;
                height: 8px;
                border-radius: 4px;
            }

            &Text {
                font-family: Manrope-Regular, sans-serif;
                color: $textPrimary;
                font-size: 16px;
            }
        }

        &Chart {
            //width: 100%;
            //height: calc(100% - 73px);

            & * {
                outline: none !important;
            }
        }
    }

    svg {
        text {
            font-size: 14px !important;
            font-family: Manrope-Medium, sans-serif !important;
            font-weight: 500 !important;
        }

        g {
            font-size: 14px !important;
            font-family: Manrope-Medium, sans-serif !important;
            font-weight: 500 !important;
        }
    }
}

.noReservesText {
    font-size: 20px;
    text-align: center;
    margin-top: 48vh;
}

.refreshButton {
    width: 47.6px;
    height: 47.6px;
    flex-shrink: 0;
    background-color: #fff;
}
